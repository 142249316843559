<template>
    <el-scrollbar class="education-wrapper" :native="false">
        <div class="steps-wrapper">
            <div class="step-item" :class="{current: [1, 2, 3].includes(stepActive)}">
                <div class="step-line"></div>
                <div class="step-text">1</div>
                <div class="step-title">创建装修数据</div>
            </div>
            <div class="step-item" :class="{current: [2, 3].includes(stepActive)}">
                <div class="step-line"></div>
                <div class="step-text">2</div>
                <div class="step-title">添加装修数据</div>
            </div>
            <div class="step-item" :class="{current: [3].includes(stepActive)}">
                <div class="step-line"></div>
                <div class="step-text">3</div>
                <div class="step-title">创建成功</div>
            </div>
        </div>
        <div class="create-content" v-if="stepActive === 1">
            <el-form :model="stepOne" :rules="stepOneRules" ref="stepOne" label-width="120px">
                <el-form-item label="创建名称" prop="d_t_name">
                    <el-input v-model="stepOne.d_t_name" placeholder="1-30个字" clearable style="width: 500px"></el-input>
                </el-form-item>
                <el-form-item label="行业分类" prop="category_id">
                    <el-select v-model="stepOne.category_id" placeholder="请选择行业分类" clearable style="width: 500px">
                        <el-option v-for="cateItem in categoryList" :key="cateItem.id" :label="cateItem.name" :value="cateItem.id"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="备注" prop="d_t_remark">
                    <el-input type="textarea" v-model="stepOne.d_t_remark" style="width: 500px"></el-input>
                </el-form-item>
            </el-form>
        </div>
        <div class="create-content" v-if="stepActive === 2">
            <div class="stepTwo-wrapper">
                <div class="form-item flex align-center">
                    <div class="item-text" style="margin-bottom: 0">使用官方数据</div>
                    <div class="item-content" style="margin-left: 20px;">
                        <el-select v-model="templateId" placeholder="自定义" clearable style="width: 250px" @change="chooseTemplate" @clear="clearTemplate">
                            <!--                            <el-option key="0" label="自定义" value="0"></el-option>-->
                            <el-option v-for="templateItem in templateList" :key="templateItem.id" :label="templateItem.d_t_name" :value="templateItem.id"></el-option>
                        </el-select>
                    </div>
                </div>
                <div class="first-title">编辑装修数据</div>
                <div class="form-item">
                    <div class="item-text flex align-center">
                        <i class="iconfont">&#xe644;</i>设置分值<span class="subtitle">总分100</span>
                        <el-input size="medium" placeholder="总分值" style="width: 100px; margin-left: 20px" disabled v-model="totalScore"></el-input>
                    </div>
                    <div class="item-content">
                        <div class="item-line flex">
                            <div class="item-line-half flex align-center">
                                <span class="half-text">商品主图</span>
                                <el-input v-model.number="stepOne.d_t_goods_img_score" oninput="value=value.replace(/[^\d]/g,'')" placeholder="设置分值" clearable></el-input>
                            </div>
                            <div class="item-line-half flex align-center">
                                <span class="half-text">店铺分类</span>
                                <el-input v-model.number="stepOne.d_t_store_class_score" oninput="value=value.replace(/[^\d]/g,'')" placeholder="设置分值" clearable></el-input>
                            </div>
                        </div>
                        <div class="item-line flex">
                            <div class="item-line-half flex align-center">
                                <span class="half-text">店招</span>
                                <el-input v-model.number="stepOne.d_t_dianzhao_score" oninput="value=value.replace(/[^\d]/g,'')" placeholder="设置分值" clearable></el-input>
                            </div>
                            <div class="item-line-half flex align-center">
                                <span class="half-text">电脑海报</span>
                                <el-input v-model.number="stepOne.d_t_pc_banner_score" oninput="value=value.replace(/[^\d]/g,'')" placeholder="设置分值" clearable></el-input>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-item">
                    <div class="item-text">
                        店铺装修素材<span class="subtitle">支持扩展名：rar和zip，文件不超过1G</span>
                    </div>
                    <div class="item-content flex" style="position: relative">
                        <el-upload
                                :action="uploadFileMethod"
                                :headers="headersParams"
                                name="file"
                                :file-list="goodsImgFile"
                                :on-success="goodsImgFileSuccess"
                                :before-upload="beforeFileUpload">
                            <el-button size="small">选择文件</el-button>
                        </el-upload>
                        <div v-if="stepOne.d_t_sucai_file" class="ml12">{{stepOne.d_t_sucai_file.name}}</div>
                    </div>
                </div>
                <div class="form-item">
                    <div class="item-text">
                        店铺装修规则<span class="subtitle">支持扩展名：doc和docx，文件不超过10M</span>
                    </div>
                    <div class="item-content flex" style="position: relative">
                        <el-upload
                                :action="uploadRuleMethod"
                                :headers="headersParams"
                                name="file"
                                :file-list="pcBannerFile"
                                :on-success="pcBannerFileSuccess"
                                :before-upload="beforeRulesUpload">
                            <el-button size="small">选择文件</el-button>
                        </el-upload>
                        <div v-if="stepOne.d_t_rule_file" class="ml12">{{stepOne.d_t_rule_file.name}}</div>
                    </div>
                </div>
<!--                <div class="form-item">-->
<!--                    <div class="item-text">-->
<!--                        店招<span class="subtitle">支持扩展名：rar和zip，文件不超过10M</span>-->
<!--                    </div>-->
<!--                    <div class="item-content flex" style="position: relative">-->
<!--                        <el-upload-->
<!--                                :action="uploadFileMethod"-->
<!--                                :headers="headersParams"-->
<!--                                name="file"-->
<!--                                :file-list="dianzhaoFile"-->
<!--                                :on-success="dianzhaoFileSuccess"-->
<!--                                :before-upload="beforeFileUpload">-->
<!--                            <el-button size="small">选择文件</el-button>-->
<!--                        </el-upload>-->
<!--                        <div v-if="stepOne.d_t_dianzhao_file" class="ml12">{{stepOne.d_t_dianzhao_file.name}}</div>-->
<!--                    </div>-->
<!--                </div>-->
            </div>
        </div>
        <div class="create-content" v-if="stepActive === 3">
            <div class="success-content">
                <i class="iconfont">&#xe730;</i>
                <p class="text">创建成功！</p>
            </div>
        </div>
        <div class="bottom-btn">
            <el-button @click="prevBtn(stepActive)" class="btn-blue-line-all" v-if="stepActive === 1 || stepActive === 2">上一步</el-button>
            <el-button @click="prevBtn(stepActive)" class="btn-blue-line-all" v-if="stepActive === 3">返回</el-button>
            <el-button class="btn-blue" @click="nextBtn(stepActive, 'stepOne')"  v-if="stepActive === 1">下一步</el-button>
            <el-button class="btn-blue" @click="nextBtn(stepActive)"  v-if="stepActive === 2">下一步</el-button>
        </div>
    </el-scrollbar>
</template>

<script>
    export default {
        data() {
            return {
                editTemplateId: Number(this.$route.query.id) || '',
                stepActive: 1, // 步骤
                stepOne: {
                    d_t_name: '',
                    category_id: '',
                    d_t_remark: '',
                    d_t_goods_img_score: null,
                    d_t_store_class_score: null,
                    d_t_dianzhao_score: null,
                    d_t_pc_banner_score: null,
                    d_t_sucai_file: null,
                    d_t_rule_file: null,
                    // d_t_dianzhao_file:null,
                },
                stepOneRules: {
                    d_t_name: [
                        { required: true, message: '请输入装修数据名称', trigger: 'blur' },
                        { min: 1, max: 30, message: '长度在 1 到 30 个字', trigger: 'blur' }
                    ],
                    category_id: [
                        { required: true, message: '请选择行业分类', trigger: 'change' }
                    ],
                    d_t_remark: [
                        { message: '请输入装修数据备注', trigger: 'blur' }
                    ],
                },
                templateId: null,
                categoryList: [],
                templateList: [],
                // start 上传文件所需
                headersParams: {
                    Authorization: localStorage.getItem('token')
                },
                uploadFileMethod: this.$api.decorateUploadFile,
                uploadRuleMethod: this.$api.decorateUploadRule,
                goodsImgFile: [],
                pcBannerFile: [],
                // dianzhaoFile: [],
                // end 上传文件所需
            }
        },
        mounted() {
            this.init()
        },
        watch: {
            $route(to, from) {
                if (to.name === 'createDecoration') {
                    this.init()
                }
            }
        },
        computed: {
            totalScore() {
                let goodImgScore = Number(this.stepOne.d_t_goods_img_score) || 0;
                let storeClassScore = Number(this.stepOne.d_t_store_class_score) || 0;
                let dianZhaoScore = Number(this.stepOne.d_t_dianzhao_score) || 0;
                let bannerScore = Number(this.stepOne.d_t_pc_banner_score) || 0;
                if (goodImgScore > -1 || storeClassScore > -1 || dianZhaoScore > -1 || bannerScore > -1) {
                    return goodImgScore + storeClassScore + dianZhaoScore + bannerScore
                } else {
                    return 0
                }
            }
        },
        methods: {
            init() {
                this.editTemplateId = this.$route.query.id
                this.stepActive = 1
                this.getCategoryList();
                this.getTemplateList();
                this.editDecorate();
            },
            // 获取行业分类列表
            getCategoryList() {
                this.$http.axiosGet(this.$api.categoryList, (res) => {
                    if (res.code === 200) {
                        this.categoryList = res.data
                    } else {
                        this.$message.warning(res.msg)
                    }
                }, (err) => {
                    console.log(err)
                })
            },
            // 获取使用官方数据列表
            getTemplateList() {
                this.$http.axiosGetBy(this.$api.decorateTemplateList, {type: 0}, (res) => {
                    if (res.code === 200) {
                        this.templateList = res.data
                    } else {
                        this.$message.warning(res.msg)
                    }
                }, (err) => {
                    console.log(err)
                })
            },
            // 筛选行业分类
            chooseTemplate(val) {
                this.$http.axiosGetBy(this.$api.decorateEditTemplate, {id: val}, (res) => {
                    if (res.code === 200) {
                        this.stepOne = {
                            d_t_name: this.stepOne.d_t_name,
                            category_id: this.stepOne.category_id,
                            d_t_remark: this.stepOne.d_t_remark,
                            d_t_goods_img_score: res.data.d_t_goods_img_score,
                            d_t_store_class_score: res.data.d_t_store_class_score,
                            d_t_dianzhao_score: res.data.d_t_dianzhao_score,
                            d_t_pc_banner_score: res.data.d_t_pc_banner_score,
                            d_t_sucai_file: res.data.d_t_sucai_file,
                            d_t_rule_file: res.data.d_t_rule_file,
                            // d_t_dianzhao_file: res.data.d_t_dianzhao_file,
                        }
                    } else {
                        this.$message.warning(res.msg)
                    }
                }, (err) => {
                    console.log(err)
                })
            },
            // 清除官方数据
            clearTemplate() {
                this.stepOne = {
                    d_t_name: this.stepOne.d_t_name,
                    category_id: this.stepOne.category_id,
                    d_t_remark: this.stepOne.d_t_remark,
                    d_t_goods_img_score: null,
                    d_t_store_class_score: null,
                    d_t_dianzhao_score: null,
                    d_t_pc_banner_score: null,
                    d_t_sucai_file: null,
                    d_t_rule_file: null,
                    // d_t_dianzhao_file:null,
                }
            },
            // 编辑装修数据
            editDecorate() {
                if (this.editTemplateId) {
                    this.$http.axiosGetBy(this.$api.decorateEditTemplate, {id: this.editTemplateId}, (res) => {
                        if (res.code === 200) {
                            this.stepOne = res.data
                            if (!this.stepOne.category_id) {
                                this.stepOne.category_id = ''
                            }
                        } else {
                            this.$message.warning(res.msg)
                        }
                    }, (err) => {
                        console.log(err)
                    })
                } else {
                    this.stepOne = {
                        d_t_name: '',
                        category_id: '',
                        d_t_remark: '',
                        d_t_goods_img_score: null,
                        d_t_store_class_score: null,
                        d_t_dianzhao_score: null,
                        d_t_pc_banner_score: null,
                        d_t_sucai_file: null,
                        d_t_rule_file: null,
                    }
                }
            },
            // 上一步按钮
            prevBtn(index) {
                if (index === 1) {
                    this.$router.go(-1)
                    this.$refs['stepOne'].resetFields();
                } else if (index === 2) {
                    this.stepActive = 1
                } else if (index === 3) {
                    this.$router.push('/admin/decorationdata')
                }
            },
            // 下一步按钮
            nextBtn(index, formName) {
                // 第一步的下一步
                if (index === 1) {
                    this.$refs[formName].validate((valid) => {
                        if (valid) {
                            let params = {
                                d_t_name: this.stepOne.d_t_name
                            }
                            if (this.editTemplateId) {
                                params.id = this.editTemplateId
                            }
                            this.$http.axiosGetBy(this.$api.decorateIssetName, params, (res) => {
                                if (res.code === 200) {
                                    this.stepActive = 2
                                    this.$message.success(res.msg)
                                } else {
                                    this.$message.warning(res.msg)
                                }
                            }, (err) => {
                                console.log(err)
                            })
                        } else {
                            console.log('error submit!!');
                            return false;
                        }
                    });
                    // 第二步的下一步
                } else if (index === 2) {
                    if (this.totalScore !== 100) {
                        this.$message.error('总分值是100分')
                        return
                    }
                    if (this.editTemplateId) {
                        this.stepOne.id = this.editTemplateId
                    }
                    this.$http.axiosPost(this.$api.decorateSaveTemplate, this.stepOne, (res) => {
                        if (res.code === 200) {
                            this.$message.success(res.msg)
                            this.stepActive = 3
                        } else {
                            this.$message.error(res.msg)
                        }
                    }, (err) => {
                        console.log(err)
                    })
                }
            },

            // 上传文件之前——>素材上传前
            beforeFileUpload(file) {
                const isZIP = file.name.endsWith('.zip') || file.name.endsWith('.rar');
                const isLt1G = file.size / 1024 / 1024 / 1024 < 1;
                if (!isZIP) {
                    this.$message.error('上传装修素材只能是 rar 或 zip 格式!');
                    return false;
                }
                if (!isLt1G) {
                    this.$message.error('上传装修素材大小不能超过 1G!');
                    return false;
                }
                return isZIP && isLt1G;
            },
            // 上传文件之前——>规则上传前
            beforeRulesUpload(file) {
                const isZIP = file.name.endsWith('.doc') || file.name.endsWith('.docx');
                const isLt10M = file.size / 1024 / 1024 < 10;
                if (!isZIP) {
                    this.$message.error('上传装修规则只能是 doc 或 docx 格式!');
                    return false;
                }
                if (!isLt10M) {
                    this.$message.error('上传装修规则大小不能超过 10MB!');
                    return false;
                }
                return isZIP && isLt10M;
            },
            // 商品主图上传成功——>装修素材
            goodsImgFileSuccess(res, file, fileList) {
                if (res.code === 200) {
                    this.goodsImgFile = [];
                    this.stepOne.d_t_sucai_file = {
                        name: file.name,
                        path: res.data
                    };
                    this.$message.success(res.msg)
                } else {
                    this.$message.error('文件上传失败，请稍后再试~')
                }
            },
            // 电脑海报上传成功——>装修规则
            pcBannerFileSuccess(res, file, fileList) {
                if (res.code === 200) {
                    this.pcBannerFile = [];
                    this.stepOne.d_t_rule_file = {
                        name: file.name,
                        path: res.data
                    };
                    this.$message.success(res.msg)
                } else {
                    this.$message.error('文件上传失败，请稍后再试~')
                }
            },
            // 店招上传成功
            // dianzhaoFileSuccess(res, file, fileList) {
            //     if (res.code === 200) {
            //         this.dianzhaoFile = [];
            //         this.stepOne.d_t_dianzhao_file = {
            //             name: file.name,
            //             path: res.data
            //         };
            //         this.$message.success(res.msg)
            //     } else {
            //         this.$message.error('文件上传失败，请稍后再试~')
            //     }
            // },
        }
    }
</script>

<style scoped lang="scss">
    .education-wrapper {
        height: 100%;
        ::v-deep .el-scrollbar__wrap {
            overflow-x: hidden;
            .el-scrollbar__view {
                padding: 60px;
            }
        }
    }
    .create-content {
        margin: 60px 0;
    }
    .bottom-btn {
        text-align: center;
    }
    .horizontal-form-item {
        display: flex;
        ::v-deep .el-form-item__content {
            margin-left: 20px;
        }
    }

    .first-title {
        font-size: 18px;
        margin-bottom: 22px;
    }
    /*自定义表单*/
    .stepTwo-wrapper {
        .form-item {
            margin-bottom: 22px;
            .item-text {
                font-size: 16px;
                margin-bottom: 15px;
                .iconfont {
                    font-size: 18px;
                    color: #1122D8;
                    margin-right: 10px;
                }
                .subtitle {
                    font-size: 14px;
                    color: #999;
                    margin-left: 20px;
                }
            }
            .item-content {
                .item-line {

                }
                .item-line-half {
                    margin: 0 30px 15px 0;
                    .half-text {
                        width: 56px;
                        text-align: right;
                    }
                    .el-input {
                        width: 1%;
                        flex: 1;
                        margin-left: 15px;
                    }
                }
            }
        }
    }
    .ml12 {
        margin-left: 12px;
        line-height: 32px;
        position: absolute;
        top: 0;
        left: 80px;
    }
    .success-content {
        margin-top: 155px;
        text-align: center;
        .iconfont {
            color: #1FD18C;
            font-size: 52px;
        }
        .text {
            font-size: 24px;
            margin: 20px 0 0;
            font-weight: bold;
        }
    }
</style>